import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { QuizzComponent } from "./quizz/quizz.component";
import { QuestionComponent } from "./quizz/question/question.component";
import { QuizzService } from "./quizz.service";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
  declarations: [AppComponent, QuizzComponent, QuestionComponent],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, FormsModule],
  providers: [QuizzService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
