import { Component, OnInit, Input } from "@angular/core";
import { Question } from "./question.model";
import { QuizzService } from "../../quizz.service";

@Component({
  selector: "app-question",
  templateUrl: "./question.component.html",
  styleUrls: ["./question.component.scss"]
})
export class QuestionComponent implements OnInit {
  @Input() question: Question;

  constructor() {}

  ngOnInit() {
    //this.getQuestionById(this.question.questionId);
  }

  /*getQuestionById(id: string): void {
    this.quizzService$
      .getQuestionById(id)
      .subscribe(questions => (this.question = questions));
  }*/
}
