import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Question } from "./quizz/question/question.model";
import { QuestionList } from "./quizz/question/questionList.model";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class QuizzService {
  private quizzUrl = "https://emolga-prod.amidori.net/api/v1/questions";
  private responseUrl = this.quizzUrl + "/result";

  constructor(private http: HttpClient) {}

  getQuestions(): Observable<Question[]> {
    return this.http.get<Question[]>(this.quizzUrl).pipe(
      tap((_) => console.log("getQuestions error")),
      catchError(this.handleError<Question[]>("getQuestions", []))
    );
  }

  postAnswer(response: QuestionList): Observable<QuestionList> {
    return this.http
      .post<QuestionList>(this.responseUrl, response)
      .pipe(catchError(this.handleError("postAnswer", response)));
  }

  getQuestion(testName: string): Observable<QuestionList> {
    const url = `${this.quizzUrl}/test/${testName}`;
    return this.http.get<QuestionList>(url).pipe(
      tap((_) => console.log("error id=${testName}")),
      catchError(this.handleError<QuestionList>(`getQuestion id=${testName}`))
    );
  }

  getQuestionById(id: string): Observable<Question> {
    const url = `${this.quizzUrl}/${id}`;
    return this.http.get<Question>(url).pipe(
      tap((_) => console.log("error id=${id}")),
      catchError(this.handleError<Question>(`getQuestionById id=${id}`))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
