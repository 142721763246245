import { Component, OnInit } from "@angular/core";
import { Question } from "./question/question.model";
import { QuizzService } from "../quizz.service";
import { QuestionList } from "./question/questionList.model";
@Component({
  selector: "app-quizz",
  templateUrl: "./quizz.component.html",
  styleUrls: ["./quizz.component.scss"],
})
export class QuizzComponent implements OnInit {
  questions: Question[];
  public currentQuestion: Question;
  nb: number = 0;
  public hasNextQuestion: boolean = false;
  public hasPreviousQuestion: boolean = false;
  public correctAnswer: number = 0;
  public displayResult: boolean = false;
  questionList: QuestionList;
  private timer: number = 60; // number of sec per question
  public timerText: string;
  public isSubmit: boolean = false;
  public questionCount: number;

  constructor(private quizzService$: QuizzService) {}

  ngOnInit() {
    this.questions = null;
    this.currentQuestion = null;
    this.nb = 0;
    this.hasNextQuestion = null;
    this.hasPreviousQuestion = null;
    this.questionList = null;
    this.questionCount = 0;
    this.timerText = null;
    this.isSubmit = false;
    this.displayResult = false;
    this.timer = 60;
    this.correctAnswer = 0;
    this.getQuestionByTestName("USCitizen");
  }

  // List all questions
  /*getQuestions(): void {
    this.quizzService$
      .getQuestions()
      .subscribe(questions => (this.questions = questions));
  }*/

  // Submit Quiz
  postAnswer(): void {
    this.timer = 0;
    this.timerText = "";
    if (this.isSubmit) {
      return;
    }
    this.isSubmit = true;
    this.quizzService$
      .postAnswer(this.questionList)
      .subscribe((questionList) => {
        this.correctAnswer = questionList.questions.length;
        this.questionList.questions = questionList.questions.filter(
          (q) => q.choice != q.correctChoice
        );
        this.correctAnswer -= this.questionList.questions.length;
        this.displayResult = true;
      });
  }

  private onGetAnswers(questionList: QuestionList) {}

  // List question by testname
  getQuestionByTestName(testName: string): void {
    this.quizzService$.getQuestion(testName).subscribe((response) => {
      this.questionList = response;
      //console.log(this.questionList);
      //this.questions = response.questions.slice(0, 10); // Get the first 10 elts
      this.questions = response.questions;
      //this.questionList.questions = this.questions;
      //console.log(this.questionList);
      if (this.questions && this.questions.length > 0) {
        this.currentQuestion = this.questions[0];
        this.hasNextQuestion = true;
      }
      this.questionCount = this.questions.length;
      this.timer = this.timer * this.questionCount;
      this.decreaseTimer();
    });
  }

  nextQuestion() {
    this.nb++;
    this.currentQuestion = this.questions[this.nb];
    this.hasNextQuestion = this.nb < this.questions.length - 1;
  }

  previousQuestion() {
    this.nb--;
    this.currentQuestion = this.questions[this.nb];
    this.hasNextQuestion = this.nb < this.questions.length - 1;
  }

  public decreaseTimer() {
    this.timerText = this.formatTimer(this.timer);
    if (this.timer > 0) {
      setTimeout(() => {
        this.timer--;
        this.decreaseTimer();
      }, 1000);
    } else {
      // Submit form automatically
      this.timerText = "";
      this.postAnswer();
    }
  }

  private formatTimer(timer: number): string {
    return (
      Math.floor(timer / 60) + ":" + (timer % 60).toString().padStart(2, "0")
    );
  }

  // Refresh the page to replay
  refreshComponent() {
    this.ngOnInit();
    //this.router.navigate([this.router.url]);
  }
}
